import React from 'react';
import Background from '../Shared/Background/Background';
import '../Shared/CSS/Core.css';
import CheckConnectionButton from '../Shared/Buttons/CheckConnectionButton';
import treasuremap from '../images/treasuremap.png';


function ConnectedPage() {
    return (
        <Background>
            <div className="pageLayout">
                <div className="headerTextLayout">
                    <div className="headerTextText">
                      The Treasure Hunt Is On!
                    </div>
                </div>
                <div className="heroImage">
                <img src={treasuremap} alt="Treasure Map" />
                </div>
                <div className="contentText">
            <p>Buckle up, because your personalised reward journey has just begun.</p>
               <p>Our financial detectives are on the case, meticulously combing through your transactions like seasoned pirates searching for hidden treasure.</p>
               <p>Every swipe, every tap, every purchase – nothing escapes their eagle eyes!</p>
               <p>This exciting hunt might take a little while, like uncovering a secret map or deciphering an ancient riddle.</p>
               <p>Soon you'll see a treasure chest overflowing with cashback, loyalty points, and exclusive deals you never knew you were missing!</p>
             <CheckConnectionButton />
                </div>
              
            </div>
            
        </Background>
    );
}

export default ConnectedPage;