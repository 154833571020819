import React from 'react';
import Background from '../Shared/Background/Background';
import '../Shared/CSS/Core.css';
import GoToInitialDashboardButton from '../Shared/Buttons/GoToInitialDashboardButton';
import explorers from '../images/explorers.png';


function MissedRewards() {
    return (
        <Background>
            <div className="pageLayout">
                <div className="headerTextLayout">
                    <div className="headerTextText">
                      The Rewards Treasure Trove Awaits
                    </div>
                </div>
                <div className="heroImage">
                <img src={explorers} alt="Explorers" />
                </div>
                <div className="contentText">
            <p>Hold your breath, treasure hunter! </p>
               <p>We are sifting through your financial sands, and unearthed... whispers of hidden riches. </p>
               <p>Cashback might be lurking, loyalty points might shimmer, and exclusive deals might just wink at you from the shadows.</p>
               <p>Brace yourself for a grand reveal!</p>
             <GoToInitialDashboardButton />
                </div>
              
            </div>
            
        </Background>
    );
}

export default MissedRewards;