import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import LandingPage from './components/LandingPage/LandingPage';
import RegisterPage from './components/RegisterPage/RegisterPage';
import LaunchConnect from './components/LaunchConnect/LaunchConnect';
import ConnectedPage from './components/ConnectedPage/ConnectedPage';
import './App.css';
import MissedRewards from './components/MissedRewards/MissedRewards';
import InitialDashboard from './components/InitialDashboard/InitialDashboard';
import AlternateRewardsPage from './components/AlternateRewardsPage/AlternateRewardsPage';
import { useEffect } from 'react';
import { Analytics } from '@vercel/analytics/react';

function App() {
  useEffect(() => {
    document.title = "Rewards Optimiser";
  }, []);
  
  return (
    <div className="page-container">
    <Router>
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/RegisterPage" element={<RegisterPage />} />
        <Route path="/LaunchConnect" element={<LaunchConnect />} />
        <Route path="/ConnectedPage" element={<ConnectedPage />} />
        <Route path="/MissedRewards" element={<MissedRewards />} />
        <Route path="/InitialDashboard" element={<InitialDashboard />} />
        <Route path="/AlternateRewardsPage" element={<AlternateRewardsPage />} />
      </Routes>
    </Router>
  < Analytics />
    </div>
  );
}

export default App;
