import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import Background from '../Shared/Background/Background';
import MissedRewardsList from '../Shared/MissedRewardsList/MissedRewardsList';
import '../Shared/CSS/Core.css';
import opentreasure from '../images/opentreasure.png';
import ShowAlternates from '../Shared/Buttons/ShowAlternates';


function InitialDashboard() {
    const [data, setData] = useState({
        count: 0,
        totalRewardsValue: 0,
        uniqueMerchants: 0,
        averageRewardValue: 0,
    });

    useEffect(() => {
      const fetchData = async () => {
          try {
              const basiqId = localStorage.getItem('basiqId');
              const apiKey = process.env.REACT_APP_POKITPAL_API_KEY;
              // Define your API URLs
              const url1 = 'https://api.dev.rewardsoptimiser.sipora.io/rewards/totals';
              const url2 = 'https://api.dev.rewardsoptimiser.sipora.io/alternates/totals'; // Replace with your second API URL

              // Call both APIs
              const responses = await Promise.all([
                  fetch(url1, {
                      method: 'POST',
                      headers: {
                        'Content-Type': 'application/json',         
                        tenantId: 'POKITPAL',
                        apiKey: apiKey
                    },
                      body: JSON.stringify({ basiqId }),
                  }),
                  fetch(url2, {
                      method: 'POST',
                      headers: {
                        'Content-Type': 'application/json',         
                        tenantId: 'POKITPAL',
                        apiKey: apiKey
                    },
                      body: JSON.stringify({ basiqId }),
                  })
              ]);

              // Check if both responses are OK
              if (!responses[0].ok || !responses[1].ok) {
                  throw new Error(`HTTP error! Status: ${responses[0].status} & ${responses[1].status}`);
              }

              // Convert both responses to JSON
              const [responseData1, responseData2] = await Promise.all(responses.map(response => response.json()));

              // Process and combine data as needed
              // Example: adding up totalRewardsValue from both responses
              const combinedData = {
                  count: responseData1.count + responseData2.count,
                  totalRewardsValue: parseFloat((responseData1.totalRewardsValue + responseData2.totalRewardsValue).toFixed(2)),
                  uniqueMerchants: responseData1.uniqueMerchants + responseData2.uniqueMerchants,
  
              };

              combinedData.averageRewardValue = combinedData.totalRewardsValue / combinedData.uniqueMerchants;


              setData(combinedData);
          } catch (error) {
              console.error('Fetching data failed', error);
          }
      };

      fetchData();

      let callCount = 0;
      const intervalId = setInterval(async () => {
          if (callCount >= 10) {
              clearInterval(intervalId); // Stop the interval after 5 calls
          } else {
              await fetchData();
              callCount++;
          }
      }, 5000); // Call the API every 10 seconds

      return () => clearInterval(intervalId); // Cleanup interval on component unmount
  }, []);


    return (
        <Background>
            <div className="pageLayout">
                <div className="headerTextLayout">
                    <div className="headerTextText">
                     Potential Riches Discovered
                    </div>
                </div>
              <div className="heroImage">
                <img src={opentreasure} alt="open treasure" />
              </div>
                <div className="statsText">
              <p>Looks like we have found something Shiny! <b>{data.count}</b> transactions could have qualified for <b>${data.totalRewardsValue} cashback</b> </p>
       
              </div>
       
             <MissedRewardsList />
             <ShowAlternates />
             
            
              
            </div>
            
        </Background>
    );
}

export default InitialDashboard;