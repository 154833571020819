import React, { useState } from 'react';
import '../Forms/forms.css';
import { useNavigate } from 'react-router-dom';

function RegistrationForm() {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    email: '',
  });
  const [isLoading, setIsLoading] = useState(false);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);

    try {
      // Triggering external API
      const apiKey = process.env.REACT_APP_POKITPAL_API_KEY;
      const response = await fetch('https://api.dev.cdr.sipora.io/user/register', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          tenantId: 'POKITPAL',
          apiKey: apiKey
        },
        body: JSON.stringify({
          tenant: 'pokitpal',
          email: formData.email, // Use formData.email instead of user.email
          // Other user details
        }),
      });

      if (!response.ok) {
        throw new Error(`API call failed: ${response.status}`);
      }

      const responseData = await response.json();
      const basiqId = responseData.basiqId;
      const userId = responseData.writeResult?.insertedId; // Optional chaining in case insertedId isn't present

      if (userId) {
        localStorage.setItem('userId', userId);
      }
      if (basiqId) {
        localStorage.setItem('basiqId', basiqId);
      }

      navigate('../LaunchConnect');
      
      setIsLoading(false);
    } catch (error) {
      console.error('Registration failed:', error);
      alert(error.message);
      setIsLoading(false);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <div>
        <label>Email:</label>
        <input 
          type="email" 
          name="email" 
          value={formData.email} 
          onChange={handleChange} 
        />
      </div>
      <button type="submit" disabled={isLoading}>
        {isLoading ? 'Loading...' : 'Register'}
        </button>
    </form>
    
  );
}

export default RegistrationForm;
