import React from 'react';
import Background from '../Shared/Background/Background';
import '../Shared/CSS/Core.css';
import RegistrationForm from '../Shared/Forms/RegistrationForm';
import lets_sail from '../images/lets_sail.png';


function RegisterPage() {
    return (

            <Background>
                <div className="pageLayout">
                    <div className="headerTextLayout">
                        <div className="headerTextText">
                            Let's Sail
                        </div>
                    </div>
                    <div className="heroImage">
                <img src={lets_sail} alt="Lets Sail" />
                </div>
                    <div className="contentText">
                    <h2>This is your VIP access point!</h2>
                    <p>Ready to uncover your hidden rewards and start living the good life?</p>
                    <p>Just drop your email address below and create a password so you can come back in the future and check for any updates</p>
                
                    <RegistrationForm />
                    </div>
                   
           
                </div>
        </Background>
    );
}

export default RegisterPage;