import React from 'react';
import Background from '../Shared/Background/Background';
import StartButton from '../Shared/Buttons/StartButton';
import treasurechest from '../images/treasurechest.png';
import '../Shared/CSS/Core.css';


function LandingPage() {
    return (
        <Background>
            <div className="pageLayout">
                <div className="headerTextLayout">
                    <div className="headerTextText">
                        Unlock Missed Rewards
                    </div>
                </div>
                <div className="heroImage">
                <img src={treasurechest} alt="Treasure Chest" />
                </div>
                <div className="contentText">   
            <p>Are you tired of missing out on amazing rewards?</p>
               <p>Imagine unlocking cashback, loyalty points, and exclusive deals - all automatically for things you already buy!</p>
               <p>The Rewards Optimiser connects securely to your bank, analyses your transactions, and show you exactly what you've been missing.</p>
               <p>Ready to see what you're leaving on the table?</p>
               <StartButton />
                </div>
               
            </div>
            
        </Background>
    );
}

export default LandingPage;