import React from 'react';
import Background from '../Shared/Background/Background';
import '../Shared/CSS/Core.css';
import ConsentButton from '../Shared/Buttons/ConsentButton';
import swords from '../images/swords.png';


function LaunchConnect() {
    return (
        <Background>
            <div className="pageLayout">
                <div className="headerTextLayout">
                    <div className="headerTextText">
                       Securely Connect With Open Banking
                    </div>
                </div>
                <div className="heroImage">
                <img src={swords} alt="OpenBanking" />
                </div>
                <div className="contentText">
            <p>You've stumbled upon a secret path to hidden rewards - the magic of Open Banking!</p>
               <p>This government-backed system lets you grant trusted apps like ours access to your bank data, safely and securely, like handing us a map to your reward treasure trove.</p>
               <p>Why is this awesome? Because we can analyze your purchases and uncover cashback, loyalty points, and exclusive deals you might have missed, all hidden in plain sight!</p>
               <p>Think of it like this: You're a pirate captain, and we're your trusty first mate. You point the ship (choose which accounts to connect), and we'll navigate the uncharted waters of your finances, bringing back juicy rewards!</p>
             <ConsentButton />
                </div>
              
            </div>
            
        </Background>
    );
}

export default LaunchConnect;