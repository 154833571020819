import React, { useEffect, useState } from 'react';

function AlternateRewardsList() {
  const [transactions, setTransactions] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const basiqId = localStorage.getItem('basiqId');
        const apiKey = process.env.REACT_APP_POKITPAL_API_KEY;
        const response = await fetch('https://api.dev.cdr.sipora.io/ro/alternates', {
          method: 'POST', // Adjust as needed
          headers: {
           tenantId: 'POKITPAL',
           apiKey: apiKey
          },
          body: JSON.stringify({
            basiqId: basiqId
          }),
        });

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();
        setTransactions(data); // Update state with the response data
      } catch (error) {
        console.error('Fetching data failed', error);
        // Handle error here
      }
    };

    fetchData();
  }, []); // The empty array ensures this effect runs once on mount

  return (
<div className="cards-container">
  <h2>List of Missed Rewards</h2>
  {transactions.map(transaction => {
    // Find the offer with the highest altRewardsValue
    const highestOffer = transaction.competitor_offers.reduce((max, offer) => 
      parseFloat(offer.altRewardsValue) > parseFloat(max.altRewardsValue) ? offer : max, 
      transaction.competitor_offers[0]
    );

    return (
      <div className="card" key={transaction._id}>
        <div className="card-header">
          You shopped at: {transaction.enrich.merchant.businessName}
        </div>
        <div className="card-body">
          <p>You could have earned: <b>${highestOffer.altRewardsValue}</b> from <b>{highestOffer.advertiser_name}</b></p>
        </div>
      </div>
    );
  })}
</div>
  );
}

export default AlternateRewardsList;
