import React, { useEffect, useState } from 'react';

function MissedRewardsList() {
  const [transactions, setTransactions] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const basiqId = localStorage.getItem('basiqId');
        const apiKey = process.env.REACT_APP_POKITPAL_API_KEY;
        const response = await fetch('https://api.dev.cdr.sipora.io/ro/rewards', {
          method: 'POST', // Adjust as needed
          headers: {
            tenantId: 'POKITPAL',
           apiKey: apiKey
          },
          body: JSON.stringify({
            basiqId: basiqId
          }),
        });

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();
        setTransactions(data); // Update state with the response data
      } catch (error) {
        console.error('Fetching data failed', error);
        // Handle error here
      }
    };

    fetchData();
    let callCount = 0;
    const intervalId = setInterval(async () => {
        if (callCount >= 10) {
            clearInterval(intervalId); // Stop the interval after 5 calls
        } else {
            await fetchData();
            callCount++;
        }
    }, 5000); // Call the API every 10 seconds

    return () => clearInterval(intervalId); // Cleanup interval on component unmount
}, []);

  return (
    <div className="cards-container">
      <h2>List of Treasures</h2>
      {transactions.map(transaction => (
        <div className="card" key={transaction._id}>
          <div className="card-header">
            Merchant: {transaction.enrich.merchant.businessName}
          </div>
          <div className="card-body">
          Missed Cashback: ${transaction.rewardValue}
            <p>Date: {new Date(transaction.postDate).toLocaleDateString()}</p>
            
          </div>
        </div>
      ))}
    </div>
  );
}

export default MissedRewardsList;
