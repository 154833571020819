import React from 'react';
import './Button.css'; // If you have separate CSS for the button
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';

function CheckConnectionButton() {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const handleButtonClick = async () => {
    setIsLoading(true);
    const basiqId = localStorage.getItem('basiqId');

    if (!basiqId) {
      console.error('No BasiqId');
      alert('It looks like you have not registered your email yet, please start again');
      return;
    }

    try {
      const apiKey = process.env.REACT_APP_POKITPAL_API_KEY;
      const response = await fetch('https://api.dev.cdr.sipora.io/ro/checkconnection', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          tenantId: 'POKITPAL',
          apiKey: apiKey
        },
        body: JSON.stringify({
          basiqId: basiqId,
          // Other user details
        }),
      });

      if (!response.ok) {
        throw new Error(`Error: ${response.status}`);
      }

      const responseText = await response.text();

      if (responseText === "Connection exists") {
        navigate('/MissedRewards');
      } else {
        alert('No connection found. Please connect your account.');
      }
      setIsLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
      alert('Failed to load data: ' + error.message);
      setIsLoading(false);
    }
  };

  return (
    <div><div class="contentText">
      {isLoading ? (
      <div>Loading...</div> // Placeholder for your loading indicator
    ) : (
      <button className="goButton" onClick={handleButtonClick}>Let's Hunt</button>
    )}
    </div></div>
  );
}

export default CheckConnectionButton;
