import React from 'react';
import { useNavigate } from 'react-router-dom';
import './Button.css'; // If you have separate CSS for the button


function ShowAlternates() {
    let navigate = useNavigate();
  
    function handleClick() {
      navigate('/AlternateRewardsPage'); // Navigate
    }
  
    return (
      <div class="contentText">
      <button className="alternateButton" onClick={handleClick}>
        Show More!
      </button>
      </div>
    );
  }
  
  export default ShowAlternates;