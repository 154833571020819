import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import Background from '../Shared/Background/Background';
import AlternateRewardsList from '../Shared/AlternateRewardsList/AlternateRewardsList';
import '../Shared/CSS/Core.css';
import spyglass from '../images/spyglass.jpg';


function AlternateRewardsPage() {
    const [data, setData] = useState({
        count: 0,
        totalRewardsValue: 0,
        uniqueMerchants: 0,
        averageRewardValue: 0,
    });

  
    return (
        <Background>
            <div className="pageLayout">
                <div className="headerTextLayout">
                    <div className="headerTextText">
                     Rewards Maximiser!
                    </div>
                </div>
              <div className="heroImage">
                <img src={spyglass} alt="more treasure" />
              </div>
                <div className="statsText">
              <p>Ahoy! Potential rewards lay bobbing in the ocean. Consider these alternate retailers next time to earn more treasures! </p>
              </div>
             <AlternateRewardsList />
            
              
            </div>
            
        </Background>
    );
}

export default AlternateRewardsPage;