import React from 'react';
import './Button.css'; // If you have separate CSS for the button
import { useState } from 'react';

function ConsentButton() {
  const [isLoading, setIsLoading] = useState(false);

  const handleButtonClick = async () => {
    setIsLoading(true); // Start loading
    const basiqId = localStorage.getItem('basiqId');

    if (!basiqId) {
      console.error('Unable to register with open banking, please try again');
      alert('Unable to register with open banking, please try again');
      return;
    }

    try {
      const apiKey = process.env.REACT_APP_POKITPAL_API_KEY;
      const response = await fetch('https://api.dev.cdr.sipora.io/user/consent', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
           tenantId: 'POKITPAL',
           apiKey: apiKey
        },
        body: JSON.stringify({
          basiqId: basiqId,
          // Other user details
        }),
      });

      if (!response.ok) {
        throw new Error(`Error: ${response.status}`);
      }

      const responseData = await response.json();
      const consentLink = responseData.consentLink;

      // Open consent link in a new tab
      window.location.replace(consentLink);
      setIsLoading(false); 
    } catch (error) {
      console.error('Error fetching data:', error);
      alert('Failed to load data: ' + error.message);
      setIsLoading(false); 
    }
  };

  return (
    <div><div class="contentText">
      {isLoading ? (
      <div>Loading...</div> // Placeholder for your loading indicator
    ) : (
      <button className="goButton" onClick={handleButtonClick}>Let's Connect</button>
    )}
      </div></div>
  );
}

export default ConsentButton;
