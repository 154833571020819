import React from 'react';
import { useNavigate } from 'react-router-dom';
import './Button.css'; // If you have separate CSS for the button


function GoToInitialDashboardButton() {
    let navigate = useNavigate();
  
    function handleClick() {
      navigate('/InitialDashboard'); // 
    }
  
    return (
      <div class="contentText">
      <button className="goButton" onClick={handleClick}>
        Show Me
      </button>
      </div>
    );
  }
  
  export default GoToInitialDashboardButton;