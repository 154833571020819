import React from 'react';
import { useNavigate } from 'react-router-dom';
import './Button.css'; // If you have separate CSS for the button


function StartButton() {
    let navigate = useNavigate();
  
    function handleClick() {
      navigate('/RegisterPage'); // Navigate to the RegisterPage
    }
  
    return (
      <button className="goButton" onClick={handleClick}>
        Let's Go
      </button>
    );
  }
  
  export default StartButton;